








import { Component, Vue } from 'vue-property-decorator';
import { getComponent, getView } from '@/utils/helpers';

@Component({
  components: {
    TasqMobileFormSubmission: () => getComponent('tasqs/TasqMobileFormSubmission'),
    AppLayout: () => getComponent('common/AppLayout'),
  },
})
export default class FormMobileSubmission extends Vue {
   windowWidth = window.innerWidth;

   stepper = 1;

   get myView() {
     return this.windowWidth < 769 ? 'tasqs-mobile' : 'tasqs-desktop';
   }

   mounted() {
     this.$nextTick(() => {
       window.addEventListener('resize', this.onResize);
     });
   }

   created() {
     //  tasqsListModule.setUsernamesList(accountModule.user.email.toLowerCase())
   }

   beforeDestroy() {
     window.removeEventListener('resize', this.onResize);
   }

   onResize() {
     this.windowWidth = window.innerWidth;
   }
}
